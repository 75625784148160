import logoViaPlus from 'assets/img/logo/logo_via_plus.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, FormFeedback, Input, Label, ModalBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import * as loginSelectors from '../store/login/reducer';
import * as loginActions from '../store/login/actions';
import Loader from './Loader';
import CustomInput from './CustomInput';

function getTokenUrl() {
  var token = window.location.pathname.split("/");
  if (token[2]) {
    return token[2];
  }
  return false;
}
function sendUrl() {
  var url = window.location.pathname.split("/");
  if (url[1] === 'change-password') {
    window.location.href = 'http://viamaisparking.com.br/';
  } else {
    window.location.href = 'login';
  }
  return;
}

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: getTokenUrl(),
      full_name: '',
      full_name_invalid: false,
      user: '',
      userInvalid: false,
      email: '',
      emailInvalid: false,
      document: '',
      document_invalid: false,
      phone: '',
      phone_invalid: false,
      birth_date: '',
      birth_date_invalid: false,
      password: '',
      passwordInvalid: false,
      terms_use: false,
      terms_use_invalid: false,
      repeat_password: '',
      repeatPasswordInvalid: false,
      loading: false,
      return: '',
      message: '',
      color_message: '',
      required: 'O Campo Nome é obrigatório',
      required_terms: 'Para criar uma conta é preciso que leia e concorde com os Termos de Uso e Políticas de Privacidade',
      required_document: 'O Campo CPF/CNPJ é obrigatório e deve ser preenchido corretamente',
      required_birth: 'O Campo Aniversário é obrigatório, e deve ser preenchido corretamente',
      required_phone: 'O Campo Telefone é obrigátorio',
      required_password: 'Deve ser preenchido de acordo com a regra a seguir. Deve conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
      required_repeat_password: 'O campo deve ser igual ao campo senha, ambos devem conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
      required_email_or_document: 'O campo preenchido deve ser email, cpf ou cnpj',
      required_email: 'Campo de e-mail é obrigatório e deve ser preenchido cor retamente.',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.callBackMessage = this.callBackMessage.bind(this);
    this.doRemember = this.doRemember.bind(this);
    this.doChange = this.doChange.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.doSignup = this.doSignup.bind(this);
  }
  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
    if (authState === 'LOGIN') {
      this.doLogin();
    }
  };
  callBackMessage(value) {
    this.setState({
      loading: true,
      message: (value.return.code === 1 ? (value.data.message ? value.data.message : value.return.message) : value.return.message),
      color_message: (value.return.code === 1 ? 'success' : ''),
    });
  }
  callBackErrorLogin(message) {
    this.setState({ message: message });
  }
  handleSubmit = event => {
    event.preventDefault();
  }
  componentWillUnmount() {
    this.setState({ loading: false });
    loginSelectors.cleanState();
  }
  doChange() {
    var invalid = false;
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({ passwordInvalid: true });
    } else {
      this.setState({ passwordInvalid: false });
    }
    if (this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({ repeatPasswordInvalid: true });
    } else {
      this.setState({ repeatPasswordInvalid: false });
    }
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null && this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null) {
      if (this.state.password.trim() !== this.state.repeat_password.trim()) {
        invalid = true;
        this.setState({ passwordInvalid: true });
        this.setState({ repeatPasswordInvalid: true });
      } else {
        this.setState({ passwordInvalid: false });
        this.setState({ repeatPasswordInvalid: false });
      }
    }

    // Check Valid
    if (invalid === true) {
      return false;
    } else {
      // Success
      this.setState({ loading: true });
      this.props.dispatch(
        loginActions.changePassword(
          this.state.token,
          this.state.password,
          this.state.repeat_password,
          this.callBackMessage
        )
      );
    }
  }
  validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

	// Valida a quantidade de caracteres
	if (cnpj.length !== 14)
		return false

	// Elimina inválidos com todos os caracteres iguais
	if (/^(\d)\1+$/.test(cnpj))
		return false

	// Cáculo de validação
	let t = cnpj.length - 2,
		d = cnpj.substring(t),
		d1 = parseInt(d.charAt(0)),
		d2 = parseInt(d.charAt(1)),
		calc = x => {
			let n = cnpj.substring(0, x),
				y = x - 7,
				s = 0,
				r = 0

				for (let i = x; i >= 1; i--) {
					s += n.charAt(x - i) * y--;
					if (y < 2)
						y = 9
				}

				r = 11 - s % 11
				return r > 9 ? 0 : r
		}

	return calc(t) === d1 && calc(t + 1) === d2
}
  validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    if (cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999")
      return false;
    var add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
      rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
      return false;
    add = 0;
    for (i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
      rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
      return false;
    return true;
  }
  doRemember() {
    if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
      this.setState({ emailInvalid: true });
      return false;
    } else {
      this.setState({ emailInvalid: false });
    }
    this.setState({ loading: true });
    this.props.dispatch(
      loginActions.rememberPassword(
        this.state.email,
        this.callBackMessage
      )
    );
  }
  equalsNumber(number) {
    if(number.indexOf('0000-0000') > -1 
    || number.indexOf('1111-1111') > -1 
    || number.indexOf('2222-2222') > -1 
    || number.indexOf('3333-3333') > -1 
    || number.indexOf('4444-4444') > -1 
    || number.indexOf('5555-5555') > -1 
    || number.indexOf('6666-6666') > -1 
    || number.indexOf('7777-7777') > -1 
    || number.indexOf('8888-8888') > -1
    || number.indexOf('9999-9999') > -1 )
    {
      return false
    }
    else{
      return true
    }
  }
  doSignup() {
    var invalid = false;
    var phone_valid = /\([1-9]\d\)\s9?\d{4}-\d{4}/
    if (this.state.full_name.length < 6) {
      invalid = true;
      this.setState({ full_name_invalid: true })
    }
    else {
      this.setState({ full_name_invalid: false })
    }
    if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
      invalid = true;
      this.setState({ emailInvalid: true });
    } else {
      this.setState({ emailInvalid: false });
    }
    //eslint-disable-next-line
    if (this.state.document.match(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/) === null) {
      invalid = true;
      this.setState({ document_invalid: true })
    }
    else {
      if(this.state.document.length === 14){
        if(this.validarCPF(this.state.document) === true){
          this.setState({ document_invalid: false })
        }
        else{
          invalid = true;
          this.setState({ document_invalid: true })
        }
      }
      else{
        if(this.validarCNPJ(this.state.document) === true){
          this.setState({ document_invalid: false })
        }
        else{
          invalid = true;
          this.setState({ document_invalid: true })
        }
      }
    }

    if (!phone_valid.exec(this.state.phone) || this.equalsNumber(this.state.phone) === false) {
      invalid = true;
      this.setState({ phone_invalid: true });
    } 
    else {
      this.setState({ phone_invalid: false });
    }
    /* if (this.state.phone.length < 14) {
      invalid = true;
      this.setState({ phone_invalid: true })
    }
    else {
      this.setState({ phone_invalid: false })
    } */
    if (this.state.birth_date === "" || this.state.birth_date === null) {
      invalid = true;
      this.setState({ birth_date_invalid: true })
    }
    else {
      if (new Date(this.state.birth_date).getFullYear() < new Date().getFullYear()) {
        this.setState({ birth_date_invalid: false })
      }
      else {
        invalid = true;
        this.setState({ birth_date_invalid: true })
      }
    }
    //eslint-disable-next-line
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({ passwordInvalid: true });
    } else {
      this.setState({ passwordInvalid: false });
    }
    if (this.state.terms_use === false) {
      invalid = true;
      this.setState({ terms_use_invalid: true });
    }
    else {
      this.setState({ terms_use_invalid: false });
    }

    //eslint-disable-next-line
    if (this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({ repeatPasswordInvalid: true });
    } else {
      this.setState({ repeatPasswordInvalid: false });
    }
    //eslint-disable-next-line
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null && this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null) {
      if (this.state.password.trim() !== this.state.repeat_password.trim()) {
        invalid = true;
        this.setState({ passwordInvalid: true });
        this.setState({ repeatPasswordInvalid: true });
      } else {
        this.setState({ passwordInvalid: false });
        this.setState({ repeatPasswordInvalid: false });
      }
    }
    // Check Valid
    if (invalid === true) {
      return false;
    } else {
      // Success
      this.setState({ loading: true });
      this.props.dispatch(
        loginActions.send_register(
          this.state.full_name,
          this.state.email,
          this.state.document,
          this.state.password,
          this.state.phone,
          this.state.birth_date,
          this.callBackMessage
        )
      );
    }
  }
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  doLogin() {
    var invalid = false;
    var username = this.state.user;
    if(this.isNumber(username) === true){
      if(username.length > 12){
        //eslint-disable-next-line
        username = this.state.user.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
        this.setState({
          //eslint-disable-next-line
          user: this.state.user.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
        })
      }
      else{
        //eslint-disable-next-line
        username = this.state.user.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
        this.setState({
          //eslint-disable-next-line
          user: this.state.user.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4")
        })
      }
    }
    //eslint-disable-next-line
    if (username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
      //eslint-disable-next-line
      if (username.match(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/) === null) {
        invalid = true;
        this.setState({ userInvalid: true })
      }
      else{
        this.setState({ userInvalid: false });
      }
    } else {
      this.setState({ userInvalid: false });
    }
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({ passwordInvalid: true });
    } else {
      this.setState({ passwordInvalid: false });
    }
    if (invalid === true) {
      return false;
    }
    this.setState({ loading: true, message: "" });
    this.props.dispatch(loginActions.do_login(username, this.state.password, this.callBackErrorLogin.bind(this)));
  }
  handlerOnChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  render() {
    const {
      showLogo,
      usernameInputProps,
      nameInputProps,
      documentInputProps,
      phoneInputProps,
      birthdateInputProps,
      passwordInputProps,
      repeatPasswordInputProps,
      children,
      onLogoClick,
      authState,
    } = this.props;

    let ShowFormGroup;
    switch (authState) {
      default:
      case 'LOGIN':
        ShowFormGroup = (
          <div>
            {showLogo && (
              <div className="text-center pb-4">
                <img
                  src={logoViaPlus}
                  className="rounded"
                  style={{ width: 190, height: 60, cursor: 'pointer' }}
                  alt="Eco Parking"
                  onClick={onLogoClick}
                />
              </div>
            )}
            <Label style={{ textAlign: 'center', width: '100%' }}>Faça o login para ter acesso a sua Carteira Digital.</Label>
            <FormGroup>
              <Input
                name="user"
                type="text"
                value={this.state.user}
                onChange={this.handleInputChange}
                invalid={this.state.userInvalid}
                placeholder='Digite seu E-mail, CPF ou CNPJ'
              />
              <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_email_or_document }}></FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                invalid={this.state.passwordInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doLogin(); } }}
                {...passwordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_password }}></FormFeedback>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" />{' '}Lembrar senha
              </Label>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doLogin}
            >Login</Button>
            <div className="text-center pt-1">
              <br />
              <h6>
                <a href="#rememberpassword" onClick={this.changeAuthState(STATE_RMPW)}>Esqueci a Senha</a>
                <br /><a href="#rememberpassword" onClick={this.changeAuthState(STATE_SIGNUP)}>Cadastrar</a>
              </h6>
            </div>
            {children}
          </div>
        );
        break;
      case 'SIGNUP':
        ShowFormGroup = (
          <div>
            <h5 style={{ textAlign: 'center', width: '100%' }}>Preencha os campos abaixo, é rapidinho!</h5>
            <br />
            <Row>
              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label style={{ margin: '0' }}>Nome Completo</Label>
                  <Input
                    name="full_name"
                    type="text"
                    value={this.state.full_name}
                    onChange={this.handleInputChange}
                    invalid={this.state.full_name_invalid}
                    {...nameInputProps}
                  />
                  <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required }}></FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup>
                  <Label style={{ margin: '0' }}>Email</Label>
                  <Input
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    invalid={this.state.emailInvalid}
                    autoComplete="novo"
                    {...usernameInputProps}
                  />
                  <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_email }}></FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <CustomInput
                  name="document"
                  type="text"
                  label="CPF ou CNPJ"
                  mask={this.state.document.length > 14 ? "00.000.000/0000-00" : "000.000.000-00000"}
                  value={this.state.document}
                  onChange={(value) => { this.handlerOnChange('document', value) }}
                  invalid={this.state.document_invalid}
                  invalid_message={this.state.required_document}
                  {...documentInputProps}
                />
              </Col>
              <Col lg={4} sm={12}>
                <CustomInput
                  name="phone"
                  type="text"
                  label="Telefone"
                  mask={this.state.phone.length > 14 ? "(00) 00000-0000" : "(00) 0000-00000"}
                  value={this.state.phone}
                  onChange={(value) => { this.handlerOnChange('phone', value) }}
                  invalid={this.state.phone_invalid}
                  invalid_message={this.state.required_phone}
                  {...phoneInputProps}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} sm={12}>
                <FormGroup>
                  <Label style={{ margin: '0' }}>Aniversário</Label>
                  <Input
                    name="birth_date"
                    type="text"
                    value={this.state.birth_date}
                    onChange={this.handleInputChange}
                    invalid={this.state.birth_date_invalid}
                    {...birthdateInputProps}
                  />
                  <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_birth }}></FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup>
                  <Label style={{ margin: '0' }}>Senha</Label>
                  <Input
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    invalid={this.state.passwordInvalid}
                    autoComplete="novo"
                    {...passwordInputProps}
                  />
                  <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_password }}></FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={4} sm={12}>
                <FormGroup>
                  <Label style={{ margin: '0' }}>Repetir Senha</Label>
                  <Input
                    name="repeat_password"
                    type="password"
                    value={this.state.repeat_password}
                    onChange={this.handleInputChange}
                    invalid={this.state.repeatPasswordInvalid}
                    autoComplete="novo"
                    {...repeatPasswordInputProps}
                  />
                  <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_repeat_password }}></FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
            </Row>
            <FormGroup check style={{ marginBottom: 7 }}>
              <Label check>
                <Input
                  name="terms_use"
                  type="checkbox"
                  invalid={this.state.terms_use_invalid}
                  onChange={this.handleInputChange} />{' '}Eu aceito os <a target="_blank" href="https://resources.ecoparking.io/terms.pdf" rel="noopener noreferrer">Termos de Uso</a> e Políticas de Privacidade
                <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_terms }}></FormFeedback>
              </Label>
            </FormGroup>
            <br />
            <div className="text-center pt-1">
              <Row>
                <Col lg={8} sm={12}>
                  <Button
                    outline
                    size="lg"
                    className=""
                    block
                    onClick={this.doSignup}
                  >Cadastrar</Button>
                </Col>
                <Col lg={4} sm={12}>
                  <h6 style={{ paddingTop: '5%', float: 'left' }}>
                    ou <a style={{ fontWeight: 'bold' }} href="#rememberpassword" onClick={this.changeAuthState(STATE_LOGIN)}>Fazer Login</a>
                  </h6>
                </Col>
              </Row>
            </div>
            {children}
          </div>
        );
        break;
      case 'RMPW':
        ShowFormGroup = (
          <div>
            {showLogo && (
              <div className="text-center pb-4">
                <img
                  src={logoViaPlus}
                  className="rounded"
                  style={{ width: 190, height: 60, cursor: 'pointer' }}
                  alt="Eco Parking"
                  onClick={onLogoClick}
                />
              </div>
            )}
            <Label style={{ textAlign: 'center', width: '100%' }}>Digite seu e-mail para recuperar a senha.</Label>
            <FormGroup>
              <Input
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                invalid={this.state.emailInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doRemember(); } }}
                {...usernameInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_email }}></FormFeedback>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doRemember}
            >Recuperar Senha</Button>
            <div className="text-center pt-1">
              <br />
              <h6>
                <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>Fazer Login</a>
              </h6>
            </div>
            {children}
          </div>
        );
        break;
      case 'CGPW':
        ShowFormGroup = (
          <div>
            {showLogo && (
              <div className="text-center pb-4">
                <img
                  src={logoViaPlus}
                  className="rounded"
                  style={{ width: 190, height: 60, cursor: 'pointer' }}
                  alt="Eco Parking"
                  onClick={onLogoClick}
                />
              </div>
            )}
            <Label style={{ textAlign: 'center', width: '100%' }}>Digite a nova senha do seu login.</Label>
            <FormGroup>
              <Input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                invalid={this.state.passwordInvalid}
                {...passwordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_password }}></FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                name="repeat_password"
                type="password"
                value={this.state.repeat_password}
                onChange={this.handleInputChange}
                invalid={this.state.repeatPasswordInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doChange(); } }}
                {...repeatPasswordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{ __html: this.state.required_repeat_password }}></FormFeedback>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doChange}
            >Alterar Senha</Button>
            {children}
          </div>
        );
        break;
    }
    return (
      <div>
        <Form onSubmit={this.handleSubmit} hidden={this.state.loading} autoComplete="new_form">

          {ShowFormGroup}
        </Form>
        <Loader
          body={ModalBody}
          hidden={!this.state.loading}
          message={this.state.message}
          color_message={this.state.color_message}
          onClose={() => this.setState({ loading: false, message: '', color_message: '' })}
          onCloseSuccess={() => sendUrl()}
        />
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';
export const STATE_RMPW = 'RMPW';
export const STATE_CGPW = 'CGPW';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP, STATE_RMPW, STATE_CGPW]).isRequired,
  showLogo: PropTypes.bool,
  usernameInputProps: PropTypes.object,
  nameInputProps: PropTypes.object,
  documentInputProps: PropTypes.object,
  phoneInputProps: PropTypes.object,
  birthdateInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};
AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameInputProps: {
    type: 'email',
    placeholder: 'email@viaparking.com',
  },
  nameInputProps: {
    type: 'text',
    placeholder: 'Nome Completo',
  },
  phoneInputProps: {
    type: 'text',
    placeholder: 'Telefone | Celular'
  },
  birthdateInputProps: {
    type: 'date',
    placeholder: 'Aniversário'
  },
  documentInputProps: {
    type: 'text',
    placeholder: 'CPF | CNPJ',
  },
  passwordInputProps: {
    type: 'password',
    placeholder: 'Senha',
  },
  repeatPasswordInputProps: {
    type: 'password',
    placeholder: 'Repita a Senha',
  },
  onLogoClick: () => { },
};

// which props do we want to inject, given the global store state?
function mapStateToProps(state) {
  return {
    user: loginSelectors.getUser(state),
    error: loginSelectors.getErrorLogin(state)
  };
}
export default connect(mapStateToProps)(AuthForm);