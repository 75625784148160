export default class BaseService {

  // Localhost
  // static ENDPOINT = 'https://ecoparkingoliveira/api/';

  // Server Homologação
  // static ENDPOINT = 'https://homologacao.api.ecoparking.io/api/';

  // Server Produção
  static ENDPOINT = 'https://api.ecoparking.io/api/';

  // Key by GoogleMapReact
  static KeyGoogleMap = 'AIzaSyADRTv2mbTc3pU259dOL_v8Q5111vspvxw';
}