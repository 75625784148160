import logoViaPlus from 'assets/img/logo/logo_via_plus_sidebar.png';
import React from 'react';
import {
  MdExitToApp,
  MdPeople,
  MdLocalShipping,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import * as loginActions from '../../store/login/actions';
import * as loginSelectors from '../../store/login/reducer';
import { connect } from 'react-redux';
import bn from 'utils/bemnames';

const navItems = [
  { id: 1, to: '/',           name: 'Carteira Digital', exact: false, Icon: MdPeople },
  { id: 2, to: '/buy-credit', name: "Comprar Créditos", exact: false, Icon:MdLocalShipping },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      selected_menu: '',
    };
    this.isActive = this.isActive.bind(this);
    this.logout = this.logout.bind(this);
  }
  isActive(TagId, Folder){
    return (Folder===window.location.pathname?'active':(TagId===this.state.selected_menu?'active':''));
  }
  setFilter = TagId => () => {
    this.setState({
      selected_menu: TagId,
    });
  }
  logout() {
    this.props.dispatch(loginActions.logout());
  }
  redirectPage = link => () => {
    window.location.ref = link;
  }
  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Navbar>
            <img
              src={logoViaPlus}
              width="200"
              className="pr-2"
              alt="ConectaModelos"
              style={{marginTop: '40px', marginBottom: '30px'}}
            />
          </Navbar>
          <Nav vertical>
            {navItems.map(({ id, to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className={this.isActive(id, to)}
                  onClick={this.setFilter(id)}
                  tag={NavLink}
                  to={to}
                  activeClassName=""
                  exact={exact}>
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem key={5} className={bem.e('nav-item')}>
              <a id="navItem-Logout-4"
                className='nav-link'
                onClick={this.logout} 
                title="Logout"
                alt="Logout"
                href="/">
                <MdExitToApp className={bem.e('nav-item-icon')} />
                <span className="">Sair</span>
              </a>
            </NavItem>
          </Nav>
        </div>
      </aside>
    );
  }
}
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
}
export default connect(mapStateToProps)(Sidebar)