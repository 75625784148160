import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Strings from '../strings/Strings';
import BaseService from '../services/BaseService';
import Loader from './Loader';
import CustomInput from './CustomInput';
import SelectAPI from './SelectAPI';

// Moip
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';

/* const pubKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvnzpUjHi59KbQOXDaGL3
                oTFTRvHQyusXC5J2BIPDBBbRsJqbhI4Wk5XWP9w6FrviyniMfcOJu6+l45AXHHd1
                7wOu+H9pFPCajP8v1SUO3grAXPuPnBv1feHJAm0aE2iA5GI26IfjbW2piq1VC/M1
                lXF9go03jENJg/GQGk4PZgeDkl8KEvPTglmiIc87zRwLat1UGXD3d4uSlQ+Tvkh5
                RvzVOaLy5dq2hgW9LH6d/GVnJg1AMl1KLD8Rjk+kO/d2WUHHupwUQCjJd1jrq4eX
                4pxVHjMTqHOfbps1gY27Tbghlhl8ibi7y8lkNl2kjPQIc32G8Z1NSbuQtyHh7UH/
                +wIDAQAB
                -----END PUBLIC KEY-----`; */
const pubKey = `-----BEGIN PUBLIC KEY-----
             MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtGYqEHUUx11kBgp9J2sR
             abmQKz8j5xLUX09NAMFQfoKlWR+WargniMNu0iieivZpB+gv/kXuWUCtEFA4739e
             D2fxBcgDWCJfQvZyCgPACwyhAWMBEhLCyNEHu0jOT6uLEhhbTkcgeJOobYnzMCZy
             5kszHZ6qcLND7NVjJq3WwdBP1388ralxan/CbbXlL4JNb5GGI2edTQsJ0A2r18wI
             Pq4gouCgl1co1vu0wd4gtlNroic5WdJ71xhkT/6gwPe1Bjl7K9yppSzX9uX22hSa
             6xwb5tZL/XFA3mXHAK0YbKuxGpbmpBw6KtPUYs1Y+R94oSVMsdrMJ+7cjD0WLbue
             jQIDAQAB
             -----END PUBLIC KEY-----`;

class CreditCardCreate extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      name: '',
      nameInvalid: false,
      birthdate: '',
      birthdateInvalid: false,
      document: '',
      documentInvalid: false,
      street: '',
      streetInvalid: false,
      street_number: '',
      streetNumberInvalid: false,
      complement: '',
      complementInvalid: false,
      district: '',
      districtInvalid: false,
      city: null,
      cityInvalid: false,
      state: null,
      stateInvalid: false,
      zip_code: '',
      zipCodeInvalid: false,
      card_flag: '',
      card_number: '',
      cardNumberInvalid: false,
      expiration: '',
      expirationInvalid: false,
      cvc: '',
      cvcInvalid: false,
      card_hash: '',
      loading: false,
      message: '',
      color_message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.valid_field = this.valid_field.bind(this);
    this.sleep = this.sleep.bind(this);
    this.register = this.register.bind(this);
  }
  componentDidMount(){

  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = ( target.type === 'checkbox' ? target.checked : (target.name!=='name'?target.value:target.value.toUpperCase() ) );
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  toggle(refresh){
    this.setState({
      name: '',
      nameInvalid: false,
      birthdate: '',
      birthdateInvalid: false,
      document: '',
      documentInvalid: false,
      street: '',
      streetInvalid: false,
      street_number: '',
      streetNumberInvalid: false,
      complement: '',
      complementInvalid: false,
      // district: '',
      // districtInvalid: false,
      // city: null,
      // cityInvalid: false,
      // state: null,
      // stateInvalid: false,
      zip_code: '',
      zipCodeInvalid: false,
      card_flag: '',
      card_number: '',
      cardNumberInvalid: false,
      expiration: '',
      expirationInvalid: false,
      cvc: '',
      cvcInvalid: false,
      card_hash: '',
      loading: false,
      message: '',
      color_message: '',
    });
    this.props.toggle(refresh);
  }
  valid_field(){
    var invalid = false;
    if(this.state.name.length < 1){
      this.setState({nameInvalid: true});
      invalid = true;
    } else {
      this.setState({nameInvalid: false});
    }
    if(this.state.card_number.length<17){
      this.setState({cardNumberInvalid: true});
      invalid = true;
    } else {
      this.setState({cardNumberInvalid: false});
    }
    if(this.state.expiration.length!==7){
      this.setState({expirationInvalid: true});
      invalid = true;
    } else {
      this.setState({expirationInvalid: false});
    }
    if(this.state.cvc.length<3){
      this.setState({cvcInvalid: true});
      invalid = true;
    } else {
      this.setState({cvcInvalid: false});
    }

    if (this.state.street.length <= 6) {
      this.setState({ streetInvalid: true });
      invalid = true;
    } else {
      this.setState({ streetInvalid: false });
    }
    if (this.state.street_number === '') {
      this.setState({ streetNumberInvalid: true });
      invalid = true;
    } else {
      this.setState({ streetNumberInvalid: false });
    }
    // if (this.state.district === '') {
    //   this.setState({ districtInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ districtInvalid: false });
    // }
    // if (this.state.city === null || this.state.city.length <= 0) {
    //   this.setState({ cityInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ cityInvalid: false });
    // }
    // if (this.state.state === null || this.state.state.length <= 0) {
    //   this.setState({ stateInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ stateInvalid: false });
    // }
    if (this.state.zip_code.length < 8) {
      this.setState({ zipCodeInvalid: true });
      invalid = true;
    } else {
      this.setState({ zipCodeInvalid: false });
    }    

    // Check Validations
    if(invalid===true){
      return false;
    }

    // Success
    return true;
  }
  sleep(milliseconds){
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  register() {
     if(this.valid_field() === false) {
       return false;
    }
    this.setState({loading: true});
    var dateExpiration = this.state.expiration;
    dateExpiration = dateExpiration.split("/");

    // var cardHash;
    // MoipCreditCard
    // .setEncrypter(JSEncrypt, 'node')
    // .setPubKey(pubKey)
    // .setCreditCard({
    //     number: this.state.card_number,
    //     cvc: this.state.cvc,
    //     expirationMonth: dateExpiration['0'],
    //     expirationYear: dateExpiration['1']
    // })
    // .hash()
    // .then((hash) => {
    //   cardHash = hash;
    // });

    this.sleep(1000).then(() => {
      var cardFlag = MoipValidator.cardType(this.state.card_number);
      if(cardFlag!==null){
        cardFlag = cardFlag['brand'];
      } else {
        cardFlag = 'NULL';
      }

      var cardNumber = this.state.card_number;
      cardNumber = cardNumber.replace(' ', '').replace(' ', '').replace(' ', '');
      // cardNumber = cardNumber.substring((cardNumber.length), (cardNumber.length-4));

      this.setState({loading: true});
      let url = BaseService.ENDPOINT + 'driver/payment/method';
      var form = new FormData();
      form.append("name", this.state.name);
      form.append("card_number", cardNumber);
      form.append("card_flag", cardFlag);
      form.append("expiration", this.state.expiration);
      form.append("cvc", this.state.cvc);
      // form.append("hash", cardHash);

      form.append("street", this.state.street);
      form.append("street_number", this.state.street_number);
      form.append("complement", this.state.complement);
      // form.append("district", this.state.district);
      // form.append("city", this.state.city.id);
      // form.append("state", this.state.state.id);
      form.append("zip_code", this.state.zip_code);

      return fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.props.user.access_token,
        }),
        body: form
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.return.code === 1){
          this.setState({
            loading : true,
            message : 'Cartão cadastrado com sucesso!',
            color_message: 'success',
          });
        } else if(json.return.code === '-403'){
          window.location = '/';
        } else {
          this.setState({
            loading: true,
            message: json.return.message
          });
        }
      });
    });
  }
  render() {
    return (
      <Modal isOpen={this.props.visible}>
        <ModalHeader>Novo Cartão de Crédito</ModalHeader>
          <ModalBody hidden={this.state.loading}>
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  maxLength={100}
                  name="name"
                  placeholder="Nome Completo"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  invalid={this.state.nameInvalid}>
                </Input>
                <FormFeedback>{Strings.required_field}</FormFeedback>
              </FormGroup>
              <CustomInput
                invalid={this.state.cardNumberInvalid}
                mask={"0000 0000 0000 0000"}
                name="card_number"
                placeholder='Número do Cartão'
                value={this.state.card_number}
                onChange={(value) => {this.setState({card_number: value})}}
                unmask={false}
                invalid_message={Strings.required_field}
              />
              <CustomInput
                invalid={this.state.expirationInvalid}
                mask={"00/0000"}
                name="expiration"
                placeholder='Data Expirar'
                value={this.state.expiration}
                onChange={(value) => {this.setState({expiration: value})}}
                unmask={false}
                invalid_message={Strings.required_field}
              />
              <CustomInput
                invalid={this.state.cvcInvalid}
                mask={"0000"}
                name="cvc"
                placeholder='CVC'
                value={this.state.cvc}
                onChange={(value) => {this.setState({cvc: value})}}
                unmask={false}
                invalid_message={Strings.required_field}
              />
              <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="street"
                placeholder="Endereço"
                value={this.state.street}
                onChange={this.handleInputChange}
                invalid={this.state.streetInvalid}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup>
            <CustomInput
              invalid={this.state.streetNumberInvalid}
              mask={"000.000.000"}
              name="street_number"
              placeholder='Número'
              value={this.state.street_number}
              onChange={(value) => { this.setState({ street_number: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="complement"
                placeholder="Complemento"
                value={this.state.complement}
                onChange={this.handleInputChange}
                invalid={false}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup>            
            {/* <SelectAPI
              placeholder={Strings.state}
              name="state"
              access_token={this.props.user.access_token}
              api="driver/states"
              selectedOption={this.handleInputChange}
              value={this.state.state}
              invalid={this.state.stateInvalid}
              formFeedbackMsg={Strings.required_field}
              multi={false}
            /> */}
            {/* <SelectAPI
              placeholder={Strings.city}
              hidden={!(this.state.state !== '')}
              name="city"
              access_token={this.props.user.access_token}
              api={"driver/cities/" + (this.state.state !== null ? this.state.state.id : '')}
              selectedOption={this.handleInputChange}
              value={this.state.city}
              invalid={this.state.cityInvalid}
              formFeedbackMsg={Strings.required_field}
              multi={false}
            /> */}
            {/* <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="district"
                placeholder="Bairro"
                value={this.state.district}
                onChange={this.handleInputChange}
                invalid={this.state.districtInvalid}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup> */}
            <CustomInput
              invalid={this.state.zipCodeInvalid}
              mask={"00000-000"}
              name="zip_code"
              placeholder='CEP'
              value={this.state.zip_code}
              onChange={(value) => { this.setState({ zip_code: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />              
            </Form>
          </ModalBody>
          <Loader
            body={ModalBody}
            hidden={!this.state.loading}
            message={this.state.message}
            color_message={this.state.color_message}
            onClose={() => this.setState({loading: false, message: '', color_message: ''})}
            onCloseSuccess={() => {this.toggle(true); this.setState({loading: false, message: '', color_message: ''});}}
          />
          <ModalFooter hidden={this.state.loading}>
            <Button color="secondary" onClick={this.register}>Cadastrar</Button>
            <Button color="danger" onClick={() => this.toggle(false)}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default CreditCardCreate;