import React from 'react';
import {FormGroup, Label, Input, FormFeedback} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import BaseService from '../services/BaseService';

class SelectAPI extends React.Component {
  constructor(props){
    super(props);
    this.state = {creatable: true, multi: false, backspaceRemoves: true, data : []}
    this.onChange = this.onChange.bind(this);
    this.getData = this.getData.bind(this);
  }
  getInitialState() {
    return {
      backspaceRemoves: true,
      multi: false,
      creatable: false,
    };
  }
  onChange(value) {
    if(value == null){
      value = "";
    }
    this.props.selectedOption({ target : {
       value: value,
       type: "select",
       name: [this.props.name]
    }});
    this.setState({value : value});
  }
  getData(input) {
    let url = BaseService.ENDPOINT;
    let method = 'GET';
    let form = '';
    let method_headers = '';
    if(this.props.method==='POST'){
      method = this.props.method;
      url += this.props.api;
      form = new FormData();
      form.append("search", input);
      form.append("input", this.props.formInput);
      method_headers = {
        method: method,
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + this.props.access_token,
        }),
        body: form
      };
    } else {
      let search = '';
      let return_all = 1;
      if(input!==undefined){
        search=input;
        return_all=0;
      }
      url += this.props.api+'?search='+search+'&all='+return_all;
      method_headers = {
        method: method,
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + this.props.access_token,
        })
      };
    }
    return fetch(url, method_headers)
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        return json.data;
      }else if(json.return.code === -403){
        window.location = '/';
      }
    });
  }
  render () {
    let PropsLabel = '';
    if(this.props.label){
      PropsLabel = (<Label for={this.props.name}>{this.props.label}</Label>);
    }
    return (
      <FormGroup hidden={this.props.hidden}>
        {PropsLabel}
        <Input hidden invalid={this.props.invalid}/>
        <AsyncSelect
          className={this.props.className}
          name={this.props.name}
          cacheOptions
          defaultOptions
          placeholder={this.props.placeholder}
          value={this.props.value} 
          onChange={this.onChange}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          loadOptions={this.getData} 
          backspaceRemoves={this.state.backspaceRemoves} 
          isMulti={this.props.multi} />
        <FormFeedback >{this.props.formFeedbackMsg}</FormFeedback>
      </FormGroup>
    );
  }
}
export default SelectAPI;