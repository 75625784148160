import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import BaseService from '../services/BaseService';
import _ from 'lodash';

class ShowTickets extends React.Component{
  constructor(props) {
    super(props);
  	this.state = {
      data_card:  {},
      selected_ticket:  '',
  		ticket_value:  '',
      ticket: '',
      ticketInvalid: false,
  	};
    this.setFilter = this.setFilter.bind(this);
    this.isActive = this.isActive.bind(this);
    this.getCards = this.getCards.bind(this);
  }
  componentWillUpdate(nextProps, nextState) {
    if( (nextProps.editing !== null && nextProps.editing !== this.props.editing) || (nextProps.reload !== this.props.reload)) {
      this.getCards();
      this.setState({
        selected_ticket:  '',
        ticket_value:  '',
        ticket: '',
        ticketInvalid: false,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.vehicle_type !== prevProps.vehicle_type) {
      this.getCards();
    }
  }
  componentDidMount(){
    this.getCards();
  }
  setFilter(obj) {
    this.setState({
      selected_ticket: obj.id,
      ticket_value: obj.value,
      ticket: !this.state.ticket,
    });
    this.props.ticket(obj);
  }
  isActive(obj_id){
    return 'box-select-ticket '+((obj_id===this.state.selected_ticket) ?'selected':'');
  }
  getCards(){
    var concat_url = '';
    if(this.props.vehicle_type!==undefined&&this.props.vehicle_type!==null){
      concat_url = '?vehicle_type_id='+this.props.vehicle_type.id;
    }
    const url = BaseService.ENDPOINT + 'driver/parking-credits'+concat_url;
    return fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization' : 'Bearer ' + this.props.user.access_token
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
        	data_card: json.data
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      }
    });
  }
  render() {
    var StyleBoxSelection = '';
    let DivBoxSelection = '';
    if(this.props.ticketInvalid===true){
      StyleBoxSelection = ' ticket-not-selected ';
      DivBoxSelection = (<Label className="text-ticket-not-selected" for={this.props.name}>{this.props.formFeedbackMsg}</Label>);
    }
    let TitleTickets = 'TICKETS';
    if(!this.props.sell){
      TitleTickets = 'R$';
    }
    let FormInsert = this.props.FormInsert;
    let DivShowTickets = [];
    _.map(this.state.data_card, (obj) => {
      DivShowTickets.push(
        <Col key={obj.id} lg="2" md="4" sm="6" xs="6">
          <div className={StyleBoxSelection+this.isActive(obj.id)} onClick={this.setFilter.bind(this, obj)}>
            <h3 hidden={!this.props.sell}>{obj.credit}h</h3>
            <Label className="ticket" hidden={!this.props.sell}>{obj.credit} ticket{(obj.credit>1?'s':'')}<br/><span style={{color: 'gray'}}>{obj.vehicle_type_id}</span></Label>
            <Label className="ticket-value">R$ {obj.value},00</Label>
          </div>
        </Col>
      );
    })
    return (
      <div style={{marginBottom: '30px'}}>
        <Row>
          <Label className="title-box-selection-tickets">{TitleTickets}</Label>
        </Row>
        <Row className="box-selection-tickets" style={{marginLeft: 15}}>
          {DivShowTickets}
        </Row>
        {DivBoxSelection}
        <div className="form-insert">
          <FormInsert />
        </div>
        <Row>
          <Label className="label-total-value-tickets">Total: <span>R$ {(this.state.ticket_value===''?0:this.state.ticket_value)},00</span></Label>
        </Row>
      </div>
    )
  }
}
export default ShowTickets;