
import BaseService from './BaseService';

class LoginService
{
  async do_login(email, password, callbackError)
  {
    const url = BaseService.ENDPOINT + 'login';
    var form = new FormData();
    form.append("user", email);
    form.append("password", password);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-System': '1'
      },
      body: form
    });
    if (!response.ok) {
      throw new Error('LoginService failed ' + url + ', HTTP status ');
    }
    const json = await response.json();
    
    if(json.return.code !== 1){
      callbackError(json.return.message);
      return null;
    }

    const user = json.data;
    if (!user) {
      return null;
    }

    return user;
  }

  async rememberPassword(email, callback)
  {
    const url = BaseService.ENDPOINT + 'remember-password';
    var form = new FormData();
    form.append("email", email);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-System': '1'
      },
      body: form
    });
    const json = await response.json();
    callback(json);
    return null;
  }
  
  async send_register(name, email, document, password, phone, birthdate, callback)
  {
    const url = BaseService.ENDPOINT + 'register';
    var form = new FormData();
    form.append("uid", "");
    form.append("name", name);
    form.append("email", email);
    form.append("document", document);
    form.append("password", password);

    var phoneNumber = phone.trim().replace("-", "");
    phoneNumber = phoneNumber.replace("(","");
    phoneNumber = phoneNumber.replace(")","");
    phoneNumber = phoneNumber.replace(" ","");
    
    form.append("phone_number", phoneNumber);
    form.append("birthdate", birthdate);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Access-System': '1'
        },
        body: form
    });

    const json = await response.json();
    callback(json);
    return null;
  }

  async changePassword(token, password, repeat_password, callback)
  {
    const url = BaseService.ENDPOINT + 'change-password';
    var form = new FormData();
    form.append("password", password);
    form.append("repeat_password", repeat_password);
    form.append("remember_token", token);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-System': '1'
      },
      body: form
    });
    const json = await response.json();
    callback(json);
    return null;
  }

  async validToken(access_token)
  {
    const url = BaseService.ENDPOINT + 'login';
    var form = new FormData();
    form.append("access_token", access_token);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-System': '1'
      },
      body: form
    });
    if (!response.ok) {
      throw new Error('LoginService failed ' + url + ', HTTP status ');
    }
    const json = await response.json();
    const user = json.data;
    if (!user) {
      return null;
    }
    return user;
  }
}

export default new LoginService();