import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';
import LinkDeveloper from 'components/LinkDeveloper';

const Footer = () => {
  var year = new Date();
  year = year.getUTCFullYear();
  return (
    <Navbar className="footer">
      <Nav navbar>
        <NavItem>
          &copy; {year} EcoParking <LinkDeveloper />
        </NavItem>
      </Nav>
    </Navbar>
  );
};
export default Footer;