import React from 'react';
import {CardBody, Progress, Alert, Button } from 'reactstrap';
import Strings from '../strings/Strings';

class Loader extends React.Component {
  render () {
    const BaseBody = (this.props.body === undefined ? CardBody : this.props.body)
    return (
      <BaseBody hidden={this.props.hidden} >
        <center hidden={!(this.props.message === "")}>{Strings.loading}...</center>
        <Progress
          animated
          value={100}
          className="mb-3"
          hidden={!(this.props.message === "")}
        />
        <Alert
          color={(this.props.color_message !== '' ? this.props.color_message : 'danger' )}
          hidden={(this.props.message === "")} style={{textAlign:"center"}}>
          <div className="justify-content-between align-items-center" >
            <p style={{marginTop: '10px', textAlign: 'center'}} dangerouslySetInnerHTML={{__html: this.props.message}}></p>
          </div>
          <Button
            color={(this.props.color_message !== '' ? this.props.color_message : 'danger' )}
            onClick={ (e) => ( this.props.color_message === '' ? this.props.onClose() : this.props.onCloseSuccess() ) }
            hidden={(this.props.message === "")}>{Strings.goback}</Button>
        </Alert>
      </BaseBody>
    );
  }
}
export default Loader;