import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Strings from '../strings/Strings';
import BaseService from '../services/BaseService';
import Loader from './Loader';

class ChangePassword extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      old_password: '',
      oldPasswordInvalid: false,
      new_password: '',
      newPasswordInvalid: false,
      repeat_password: '',
      repeatPasswordInvalid: false,
      loading: false,
      message: '',
      color_message: '',
      required_password: 'Deve ser preenchido de acordo com a regra a seguir. Deve conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
      required_repeat_password: 'O campo deve ser igual ao campo "nova senha", ambos devem conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.validField = this.validField.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.editing !== null && nextProps.editing !== this.props.editing) {
      this.setState({
        old_password: '',
        new_password: '',
        repeat_password: '',
        color_message: '',
      });
    }
  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = target.type === 'checkbox' ? target.checked: target.value;
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  toggle(refresh){
    this.setState({
      old_password: '',
      oldPasswordInvalid: false,
      new_password: '',
      newPasswordInvalid: false,
      repeat_password: '',
      repeatPasswordInvalid: false,
      message: '',
      color_message: '',
      status: ''
    });
    this.props.toggle(refresh);
  }
  validField(){
    var invalid = false;
    if (this.state.old_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      this.setState({oldPasswordInvalid : true});
      invalid = true;
    } else {
      this.setState({oldPasswordInvalid : false});
    }
    if (this.state.new_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({newPasswordInvalid: true});
    } else {
      this.setState({newPasswordInvalid: false});
    }
    if (this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({repeatPasswordInvalid: true});
    } else {
      this.setState({repeatPasswordInvalid: false});
    }
    if (this.state.new_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null && this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) !== null){
      if (this.state.new_password.trim()!==this.state.repeat_password.trim()) {
        invalid = true;
        this.setState({newPasswordInvalid: true});
        this.setState({repeatPasswordInvalid: true});
      } else {
        this.setState({newPasswordInvalid: false});
        this.setState({repeatPasswordInvalid: false});
      }
    }

    if(invalid===true){
      return false;
    }

    return true;
  }
  changePassword() {
    if(!this.validField()){
      return; 
    }
    this.setState({loading : true});
    let url = BaseService.ENDPOINT + 'driver/user/change-password';
    var form = new FormData();
    form.append("old_password", this.state.old_password);
    form.append("password", this.state.new_password);
    form.append("repeat_password", this.state.repeat_password);
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization' : 'Bearer ' + this.props.user.access_token,
      }),
      body : form
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
          loading : true,
          message : 'Senha alterada com Sucesso!',
          color_message: 'success',
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      }else{
        this.setState({
          loading: true,
          message : json.return.message
        });
      }
    });
  }
  render() {
    return (
      <Modal isOpen={this.props.visible} >
        <ModalHeader>{this.props.title}</ModalHeader>
          <ModalBody hidden={this.state.loading}>
            <Form>
              <FormGroup>
                <Input
                  type="password"
                  maxLength={100}
                  name="old_password"
                  placeholder="Senha antiga"
                  value={this.state.old_password}
                  onChange={this.handleInputChange}
                  invalid={this.state.oldPasswordInvalid}>
                </Input>
                <FormFeedback>{Strings.required_field}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  type="password"
                  maxLength={100}
                  name="new_password"
                  placeholder="Nova senha"
                  value={this.state.new_password}
                  onChange={this.handleInputChange}
                  invalid={this.state.newPasswordInvalid}>
                </Input>
                <FormFeedback>{this.state.required_password}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  type="password"
                  maxLength={100}
                  name="repeat_password"
                  placeholder="Repita a senha"
                  value={this.state.repeat_password}
                  onChange={this.handleInputChange}
                  invalid={this.state.repeatPasswordInvalid}>
                </Input>
                <FormFeedback>{this.state.required_repeat_password}</FormFeedback>
              </FormGroup>
            </Form>
          </ModalBody>
          <Loader
            body={ModalBody}
            hidden={!this.state.loading}
            message={this.state.message}
            color_message={this.state.color_message}
            onClose={() => this.setState({loading : false, message: '', color_message: ''})}
            onCloseSuccess={() => {this.toggle(true); this.setState({loading : false, message: '', color_message: ''});}}
          />
          <ModalFooter hidden={this.state.loading}>
            <Button outline color="secondary" onClick={this.changePassword}>Mudar Senha</Button>
            <Button outline color="danger" onClick={() => this.toggle(false)}>Cancelar</Button>
        </ModalFooter>
    </Modal>
    )
  }
}
export default ChangePassword;