import React, { Component } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { IMaskInput } from 'react-imask';

class CustomInput extends Component {
    constructor(props){
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this)
    }
    handleInputChange(event) {
        this.props.onChange(event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    }
    renderMask() {
        const classInput = (this.props.invalid ? "form-control is-invalid" : "form-control");
        return (
            <div>
                <IMaskInput
                    className={classInput}
                    mask={this.props.mask}
                    value={this.props.value}
                    unmask={false}
                    onAccept={
                        (value, mask) => {this.props.onChange(value)}
                    }
                    placeholder={this.props.placeholder}
                    hidden={this.props.hidden_input}
                    disabled={this.props.disabled}
                />
                <Input type="hidden" invalid={this.props.invalid}></Input>
                <FormFeedback>{this.props.invalid_message}</FormFeedback>
            </div>
        );
    }
    renderInput() {
        return (
            <div>
                <Input
                    type={this.props.type}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    maxLength={this.props.maxLength}
                    onChange={this.handleInputChange}
                    invalid={this.props.invalid}
                    hidden={this.props.hidden_input}
                    disabled={this.props.disabled}
                />
                <FormFeedback>{this.props.invalid_message}</FormFeedback>
            </div>
        );
    }
    render() {
        let HideLabel = false;
        if(this.props.hideLabel){
            if (this.props.value && this.props.value.lenght > 0){
                HideLabel = true;   
            }
        }
        let PropsLabel = '';
        if(this.props.label){
          PropsLabel = (<Label style={{margin: 0}} for={this.props.name} hidden={HideLabel}>{this.props.label}</Label>);
        }
        return (
            <FormGroup hidden={this.props.hidden}>
                {PropsLabel}
                { (this.props.mask!==null && this.props.mask!=="") ? this.renderMask() : this.renderInput() }
            </FormGroup>
        );
    }
}
export default CustomInput;