import Page from 'components/Page';

// Connection
import CustomInput from '../components/CustomInput';
import React from 'react';

import {
  Form,
  FormGroup,
  Button,
  Col,
  Row,
  Card, 
  CardBody, 
  Label
} from 'reactstrap';

// Reducer & Action
import { connect } from 'react-redux';
import * as loginSelectors from 'store/login/reducer';

// Components
import CreditCard from 'components/CreditCard';
import DeleteModal from 'components/DeleteModal';
import ChangePassword from 'components/ChangePassword';
import CreditCardCreate from 'components/CreditCardCreate';
import BaseService from '../services/BaseService';

class DashboardPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      nome: '',
      email: '',
      methodt: '',
      user_credit: 0,
      user_ticket: '',
      credit_form: true,
      show_form_password: false,
      show_form_credit_card: false,
      show_deleting : false,
      show_button_deleting : true,
      editingObj : null,
      delete_id : -1,
      reload: false,
    };
    this.closed = this.closed.bind(this);
    this.addActionPassword = this.addActionPassword.bind(this);
    this.addActionCreditCard = this.addActionCreditCard.bind(this);
    this.closedDelete = this.closedDelete.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.getDataUser = this.getDataUser.bind(this);
  }
  componentDidMount(){
    this.getDataUser();
  }
  addActionPassword(){
    this.setState({
      show_form_password: true
    });
  }
  addActionCreditCard(){
    this.setState({
      show_form_credit_card: !this.state.show_form_credit_card
    });
  }
  closed(refresh){
    if(refresh){
      this.setState({
        show_form_password: false,
        show_form_credit_card: false,
        reload: !this.state.reload,
        editingObj: null
      });
    }else{
      this.setState({
        show_form_password: false,
        show_form_credit_card: false,
        editingObj: null
      });
    }
  }
  closedDelete(refresh){
    if(refresh){
      this.setState({ show_deleting: false, reload: !this.state.reload, delete_id: -1} );
    }else{
      this.setState({ show_deleting: false , delete_id: -1});
    }
  }
  deleteAction(idx, obj){
    this.setState({ show_deleting: true , delete_id: obj.id});
  }
  getDataUser(){
    const url = BaseService.ENDPOINT + 'driver/user';
    return fetch(url, {
      method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + this.props.user.access_token,
        })
      })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
          nome: json.data.name,
          email: json.data.email,
          user_ticket: 0,
          user_credit: json.data.credit,
          document: json.data.document,
          birthdate: json.data.birthdate,
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      }
    });
  }

  render() {
    return (
      <Page
        title="Carteira Digital"
        className="comprar-tickets"
      >
      <Row>
       <Col md="2" sm="0" xs="0"></Col>
       <Col md="8" sm="12" xs="12">
         <Row>
           <Col md="6" sm="12" xs="12">
            <Form>
              <CustomInput
                  label="Nome"
                  type="text"
                  name="name"
                  value={this.state.nome}
                  disabled
                />
              <CustomInput
                  label="E-mail"
                  type="text"
                  name="email"
                  value={this.state.email}
                  disabled
                />
                <CustomInput
                  label="Telefone"
                  type="text"
                  mask={this.props.user.phone_number.length > 10 ? "(00) 00000-0000" : "(00) 0000-00000"}
                  name="phone"
                  value={this.props.user.phone_number}
                  disabled
                />
              <CustomInput
                  label="Aniversário"
                  type="date"
                  name="birth"
                  value={new Date(this.props.user.birthdate).toLocaleDateString()}
                  disabled
                />
                <CustomInput
                    label="Documento"
                    type="text"
                    name="name"
                    value={this.state.document}
                    disabled
                  />
              <FormGroup>
                <span className="label-right">
                  <a href="#change-password" onClick={this.addActionPassword} alt="Mudar Senha">Mudar Senha</a>
                </span>
              </FormGroup>
            </Form>
           </Col>
           <Col md="6" sm="12" xs="12">
           <Card className="box-saldo-comprar-tickets">
              <CardBody>
                <Row>
                  <Col>
                    <Label className="sub-title">Saldo</Label>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-text">
                    <Label className="text-session">CARTEIRA</Label>
                    <span className="text-values">R$ {parseInt(this.state.user_credit).toFixed(2)}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
           </Col>
         </Row>
       </Col>
       <Col md="2" sm="0" xs="0"></Col>
     </Row>
     <Row>
       <Col md="12" sm="12" xs="12">
         <label className="sub-title">CARTÕES CADASTRADOS</label>
       </Col>
     </Row>
     <Row>
       <Col md="12" sm="12" xs="12" className="box-button-store-credit-card">
         <Button className="button-store-credit-card" onClick={this.addActionCreditCard}>Cadastrar Novo</Button>
       </Col>
     </Row>
      <Row className="marginBottom100">
        <Col md="2" sm="0" xs="0"></Col>
        <Col md="8" sm="12" xs="12">
          <CreditCard
            showDeleting={this.state.show_button_deleting}
            user={this.props.user}
            hidden={this.state.credit_form}
            deleteAction={this.deleteAction}
            methodtInvalid={this.state.methodtInvalid}
            methodSelected={(credit) => this.setState({methodt: credit})}
            reload={this.state.reload}
          />
        </Col>
        <Col md="2" sm="0" xs="0"></Col>
      </Row>
      <ChangePassword
        title="Trocar Senha"
        visible={this.state.show_form_password}
        user={this.props.user}
        toggle={this.closed}
      />
      <CreditCardCreate
        api="driver/payment/method"
        title="Adicionar Cartão de Crédito"
        visible={this.state.show_form_credit_card}
        user={this.props.user}
        reload={this.state.reload}
        toggle={this.closed}
      />
      <DeleteModal
        api="driver/payment/method/"
        visible={this.state.show_deleting}
        user={this.props.user}
        toggle={this.closedDelete}
        value={this.state.delete_id}
      />
      </Page>
    );
  }
}
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
} 
export default connect(mapStateToProps)(DashboardPage);