import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Strings from '../strings/Strings';
import BaseService from '../services/BaseService';
import Loader from './Loader';

class DeleteModal extends React.Component{
  constructor(props){
    super(props)
    this.state = {loading: false, message: "" };
    this.toggle = this.toggle.bind(this);
    this.delete = this.delete.bind(this);
  }
  toggle(refresh){
    this.setState({name: "", spot_types_period: ""});
    this.props.toggle(refresh);
  }
  delete() {
    this.setState({loading: true});
    const url = BaseService.ENDPOINT + this.props.api + this.props.value;
		return fetch(url, {
      method: 'DELETE',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.access_token,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({loading: false});
        this.toggle(true);
      }else if(json.return.code === '-403'){
        window.location = '/';
      }else{
        this.setState({
          loading: false,
          message: json.return.message
        });
      }
		});
  }
  render() {
    return (
      <Modal isOpen={this.props.visible} >
        <ModalHeader>{Strings.confirm_delete}</ModalHeader>
          <Loader body={ModalBody} hidden={!this.state.loading} message={this.state.message} onClose={() => this.setState({loading: false, message: ""})}/>
          <ModalFooter hidden={this.state.loading} >
            <Button color="primary" onClick={this.delete}>
              {Strings.yes}
            </Button>
            <Button color="danger" onClick={() => this.toggle(false)}>
              {Strings.no}
            </Button>
        </ModalFooter>
    </Modal>
    )
  }
}
export default DeleteModal;