import * as React from 'react';
import Page from 'components/Page';
import { Button, Row, Col, Card, CardBody, Label, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import BaseService from '../services/BaseService';
import ShowTickets from 'components/ShowTickets';
import Strings from '../strings/Strings';
import CreditCard from 'components/CreditCard';
import Loader from 'components/Loader';
import * as loginSelectors from 'store/login/reducer';
import QRCode from "react-qr-code";
import { format } from 'date-fns';

class BuyCreditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_form: false,
      show_deleting: false,
      credit_form: false,
      visible: false,
      user_ticket: 0,
      user_credit: 0,
      ticket: '',
      ticketInvalid: false,
      method: '',
      methodInvalid: false,
      type_method: '',
      methodTypeInvalid: false,
      box_billet: false,
      billet_url: '',
      color_message: '',
      message: '',
      status_payment: '',
      is_status: false,
      reload: false,
      loading: false,
      created_at: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addAction = this.addAction.bind(this);
    this.closed = this.closed.bind(this);
    this.toggle = this.toggle.bind(this);
    this.valid_field = this.valid_field.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.getUser = this.getUser.bind(this);
    this.register = this.register.bind(this);
  }
  componentDidMount() {
    this.getUser();
    //this.getMostPayment();
  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  FormInsert = () => {
    return (
      <div>
        <Label className="pay-tickets-text-input"><strong>Documento</strong><br />{this.props.user.document}</Label>
        <Label className="pay-tickets-text-input" hidden={!this.state.is_status}><strong>Status do Pagamento</strong><br />{this.state.status_payment}</Label>
      </div>);
  }
  addAction() {
    this.setState({ show_form: !this.state.show_form });
  }
  closed(refresh) {
    if (refresh) {
      this.setState({ show_form: false, reload: !this.state.reload });
    } else {
      this.setState({ show_form: false });
    }
  }
  toggle(refresh) {
    this.setState({
      reload: !this.state.reload,
      ticket: '',
      ticketInvalid: false,

      type_method: '',
      credit_form: false,
      methodTypeInvalid: false,

      method: '',
      methodInvalid: false,

      ticket_id: '',
      method_id: '',

      loading: false,
      visible: false,

      box_billet: false,
      billet_url: '',
      message: '',
      color_message: '',

      created_at: '',
    });
    this.getUser();
  }
  deleteAction(obj) {
    return false;
  }
  valid_field() {
    var invalid = false;
    if (this.state.ticket === '') {
      this.setState({ ticketInvalid: true });
      invalid = true;
    } else {
      this.setState({ ticketInvalid: false });
    }
    if (this.state.type_method === '') {
      this.setState({ methodTypeInvalid: true });
      invalid = true;
    } else {
      this.setState({ methodTypeInvalid: false });
    }
    if (this.state.type_method === 1 && this.state.method === '') {
      this.setState({ methodInvalid: true });
      invalid = true;
    } else {
      this.setState({ methodInvalid: false });
    }

    if (invalid === true) {
      return false;
    }

    return true;
  }
  register() {
    this.setState({ loading: true, visible: true });
    if (!this.valid_field()) {
      this.setState({ loading: false, visible: false });
      return;
    }
    let API = "pay";
    if (this.state.type_method === 4) {
      API = "driver/payment/pix";
    }

    let url = BaseService.ENDPOINT + API;
    var form = new FormData();
    form.append("credits_id", this.state.ticket.id);
    form.append("method_id", this.state.type_method);
    
    if (this.state.type_method === 1) {
      form.append("payment_id", this.state.method.id);
    }

    // console.log("token => " + this.props.user.access_token);
    // console.log("credits_id => " + this.state.ticket.id);
    // console.log("payment_id => " + this.state.method.id);
    // console.log("method_id => " + this.state.type_method);

    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.access_token,
      }),
      body: form
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.return.code === 1) {
          if (this.state.type_method === 4) {
            var timeLimit = new Date();
            timeLimit.setMinutes(timeLimit.getMinutes() + 3);          
            this.setState({
              loading: false,
              billet_url: json.data.billet_url,
              box_billet: true,
              created_at: format(timeLimit, 'HH:mm'),
            });
          } else {
            this.getMostPayment(json.data.id);
            this.setState({
              loading: true,
              message: 'Compra realizada com sucesso! Aguarde o processamento dos seus créditos.',
              status_payment: json.data.status.name,
              is_status: true,
              color_message: 'success',
            });
          }
        } else if (json.return.code >= 300) {
          window.location = '/';
        } else {
          this.setState({
            loading: true,
            message: json.return.message
          });
        }
      });
  }
  getMostPayment(order_id) {
    var count = 0;
    var form = new FormData();
    form.append("order_id", order_id);
    count++;
    fetch(BaseService.ENDPOINT + 'pay/status', {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        "Authorization": "Bearer " + this.props.user.access_token
      }),
      body: form,
      timeout: 120
    })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.return.code === 1) {
          this.setState({
            status_payment: json.data.status.name,
          })
          if (json.data.status.id < 5 && count <= 2) {
            setTimeout(function () {
              this.getMostPayment(order_id)
            }.bind(this), 1000);
          } else {
            this.getUser();
            count = 0;
          }
        } else {
          count = 0;
        }
      }).catch(function (e) {
        count = 0;
      });
  }
  getUser() {
    const url = BaseService.ENDPOINT + 'driver/user';
    return fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.access_token,
      })
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.return.code === 1) {
          this.setState({
            user_ticket: 0,
            user_credit: json.data.credit,
            document: json.data.document,
            birthdate: json.data.birthdate,
          });
        } else if (json.return.code === '-403') {
          window.location = '/';
        }
      });
  }
  
  render() {
    let ButtonCredit = 'btn btn-success';
    // let ButtonBillet = 'btn button-billet';
    let ButtonPIX = 'btn button-billet';

    let MessageButton = '';
    if (this.state.type_method === 1) {
      ButtonCredit += ' actived';
    }
    if (this.state.type_method === 2) {
      //ButtonBillet += ' actived';
    }
    if (this.state.type_method === 4) {
      ButtonPIX += ' actived';
    }
    if (this.state.methodTypeInvalid === true) {
      ButtonCredit += ' action-invalid';
      //ButtonBillet += ' action-invalid';
      ButtonPIX += ' action-invalid';
      MessageButton = <div><br />Selecione uma das opções.</div>;
    }
    return (
      <Page
        title="Comprar Créditos"
        className="comprar-tickets">
        <Row>
          <Col md="3" sm="12" xs="12">
            <Card className="box-saldo-comprar-tickets">
              <CardBody>
                <Row>
                  <Col>
                    <Label className="sub-title">Saldo</Label>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-text">
                    <Label className="text-session">CARTEIRA</Label>
                    <span className="text-values">R$ {parseInt(this.state.user_credit).toFixed(2)}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" sm="12" xs="12">
            <ShowTickets
              spotType={''}
              user={this.props.user}
              FormInsert={this.FormInsert}
              ticketInvalid={this.state.ticketInvalid}
              ticket={(ticket) => this.setState({ ticket: ticket, ticketInvalid: false })}
              deleteAction={this.deleteAction}
              formFeedbackMsg={Strings.required_field}
              reload={this.state.reload}
            />
            <Row>
              <Col md="12" sm="12" xs="12">
                <div className="box-footer-select-form-payment">
                  <Label className="text-form-payments">FORMA DE PAGAMENTO</Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12" xs="12">
                <Row>
                  <Col md="12" sm="12" xs="12" className="button-action">
                    <button
                      className={ButtonCredit}
                      onClick={
                        () => this.setState({
                          type_method: 1,
                          credit_form: true,
                          methodTypeInvalid: false
                        })
                      }>Cartão</button>
                    {/* <button
                          className={ButtonBillet}
                          onClick={
                            () => this.setState({
                              type_method: 2,
                              credit_form: false,
                              methodTypeInvalid: false
                            })
                          }>Boleto</button> */}
                    <button
                      className={ButtonPIX}
                      onClick={
                        () => this.setState({
                          type_method: 4,
                          credit_form: false,
                          methodTypeInvalid: false
                        })
                      }>PIX</button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" sm="12" xs="12" className="button-action-message">
                    {MessageButton}
                  </Col>
                </Row>
              </Col>
            </Row>
            <CreditCard
              showDeleting={this.state.show_deleting}
              user={this.props.user}
              hidden={this.state.credit_form}
              deleteAction={this.deleteAction}
              methodInvalid={this.state.methodInvalid}
              methodSelected={(credit) => this.setState({ method: credit, methodInvalid: false })}
              formFeedbackMsg={Strings.required_field}
              reload={this.state.reload}
            />
            <Row>
              <Col className="button-action">
                <button className="btn btn-secondary marginBottom100" onClick={this.register}>Comprar</button>
              </Col>
            </Row>
          </Col>
          <Col md="1" sm="0" xs="0"></Col>
        </Row>
        <Modal isOpen={this.state.visible}>
          <div hidden={!this.state.box_billet}>
            <ModalHeader style={{ display: 'inherit' }}><center>QR Code válido até {this.state.created_at} </center></ModalHeader>
            <ModalBody hidden={this.state.loading}>
              <Row>
                <Col>
                  <center>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                      value={this.state.billet_url}
                    viewBox={`0 0 256 256`}
                    />
                  </center>
                </Col>
              </Row>
              <Row>
                <Col>
                  <center>
                    <br/>
                    <a  
                      onClick={() => {navigator.clipboard.writeText(this.state.billet_url); alert('Código PIX copiado!');}}
                      className={ButtonCredit + ' btn btn-secondary'} 
                      target="__blank" 
                      // href={this.state.billet_url}
                    >
                      Copiar código PIX</a>
                  </center>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter hidden={this.state.loading}>
              <span>Feche ou atualize a página após o pagamento para verificar seu novo saldo.</span>
              <Button outline color="danger" onClick={() => this.toggle(false)}>Fechar</Button>
            </ModalFooter>
          </div>
          <Loader
            body={ModalBody}
            hidden={!this.state.loading}
            message={this.state.message}
            color_message={this.state.color_message}
            onClose={() => { this.toggle(true); this.setState({ loading: false, message: '', color_message: '' }); }}
            onCloseSuccess={() => { this.toggle(true); this.setState({ loading: false, message: '', color_message: '' }); }}
          />
        </Modal>
      </Page>
    );
  }
}
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user: currentUser
  };
}
export default connect(mapStateToProps)(BuyCreditPage);