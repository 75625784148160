import React from 'react';
import { STATE_LOGIN, STATE_RMPW, STATE_CGPW, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';

// Pages
//import AuthModalPage from 'pages/AuthModalPage';
import AuthPage from 'pages/AuthPage';
import DashboardPage from 'pages/DashboardPage';
/* import UserAdminPage from 'pages/UserAdminPage';
import UserAgentPage from 'pages/UserAgentPage';
import UserAdviserPage from 'pages/UserAdviserPage';
import UserAccessPointPage from 'pages/UserAccessPointPage';
import VehicleResidentPage from 'pages/VehicleResidentPage';
import ReportSpotPage from 'pages/ReportSpotPage';
import SpotTypesPage from 'pages/SpotTypesPage';
import ParkingSpotsPage from 'pages/ParkingSpotsPage';
import ReportAdvisorPage from 'pages/ReportAdvisorPage';
import TicketPage from 'pages/TicketPage'; */

// Style CSS
import './styles/reduction.scss';

// Connections
import * as loginSelectors from 'store/login/reducer';
import * as loginActions from 'store/login/actions';
import { connect } from 'react-redux';
import BuyCreditPage from './pages/BuyCreditPage';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};


class MainApp extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      user: undefined
    };
    this.props.dispatch(loginActions.getUserStorage());
  }
  render(){
    if(this.props.user !== undefined && this.props.user){
      return (
        <App user={this.props.user}/>
      )
    }else{
      return (
        <BrowserRouter>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )
                }
              />
              <LayoutRoute
                exact
                path="/remember-password"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_RMPW} />
                  )
                }
              />
              <LayoutRoute
                exact
                path="/account-validator/:token"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )
                }
              />
              <LayoutRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
                    )}
              />
              <LayoutRoute
                exact
                path="/change-password/:token"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_CGPW} />
                  )
                }
              />
              <LayoutRoute
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )
                }
              />
            </Switch>
          </GAListener>
        </BrowserRouter>
      )
    }
  }
}

class App extends React.Component{
  render() {
  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
        <LayoutRoute
          exact
          path="/"
          layout={MainLayout}
          component={DashboardPage}
        />
        <LayoutRoute
          exact
          path="/buy-credit"
          layout={MainLayout}
          component={BuyCreditPage}
        />
        {/* <LayoutRoute
          exact
          path="/payment"
          layout={EmptyLayout}
          component={PaymentForm}
        />
          <LayoutRoute
            exact
            path="/account-validator/:token"
            layout={EmptyLayout}
            component={
              props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )
            }
          />
        <LayoutRoute
          exact
          path="/profile"
          layout={MainLayout}
          component={ConfiguracoesDados}
        /> */}
        <Redirect to="/" />
        </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
}
export default connect(mapStateToProps)(componentQueries(query)(MainApp));
