// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import Immutable from 'seamless-immutable';
// import _ from 'lodash';
import * as types from './actionTypes';

const initialState = Immutable({
  user: undefined,
  error : "",
});

// reducer chamado depois do retorno da conexao na actions
// com objetivo de atualziar os dados atuais
export default function reduce(state = initialState, action = {}) {
  switch(action.type) {
    case types.USER_LOGGED:
      return state.merge({
         user: action.user,
         error: action.error
      });
    case types.USER_REMEMBER:
      return state.merge({
         user: '',
         error: '',
      });
    default:
      return state;
  }
}

export function cleanState(){
  initialState.set({
    user: undefined,
    error: ""
  });
}

//selectors
export function getUser(state) {
  return state.login.user;
}

export function getErrorLogin(state) {
  return state.login.error;
}
