import AuthForm, { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } 
    else if(authState === STATE_SIGNUP){
      this.props.history.push('/signup');
    }
    else 
    {
      this.props.history.push('/remember-password');
    }
  };
  handleLogoClick = () => {
    this.props.history.push('/');
  };
  componentDidMount(){
    
  }
  render() {
    return (
      <Row
        style={{
          background: 'linear-gradient(rgb(30, 125, 49), rgb(57, 147, 86))',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Col md={6} lg={this.props.history.location.pathname === '/signup' ? 8 : 4}>
          <Card 
            body
            style={{ boxShadow: '1px 0px 15px rgba(0,0,0,0.5)' }}>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
export default AuthPage;